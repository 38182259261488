import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '../../components/icon/Icon';
import csFullLogo from '../assets/csFullLogo.png';
import { mainPagesMenu } from '../menu.Cyberstorm';

const Brand = ({ asideStatus, setAsideStatus }) => {
	return (
		<div className='brand'>
			<div className='brand-logo me-3'>
				<h1 className='brand-title '>
					<Link to={mainPagesMenu.homePage.path} aria-label='Logo'>
						<img src={csFullLogo} alt='csFullLogo' className='full-logo' />
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
