import React, { lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { authPagesMenu, mainPagesMenu, hdiePagesMenu, defaultPagesMenu } from '../menu.Cyberstorm';

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ children }) => {
	// check logged in state and set user details
	const userToken = sessionStorage.getItem('userID');
	let userAuthenticated;
	if (userToken === null) {
		userAuthenticated = false;
	} else {
		userAuthenticated = true;
	}
	const navigate = useNavigate();
	if (userAuthenticated) {
		return children;
	} else {
		navigate(authPagesMenu.loginPage.path);
	}
};

const MAIN_PAGES = {
	HOME_PAGE: lazy(() => import('../pages/HomePage')),
	WHAT_WE_DO_PAGE: lazy(() => import('../pages/whatWeDoPages/WhatWeDoPage')),
	OUR_WORK_PAGE: lazy(() => import('../pages/ourWorkPages/OurWorkPage')),
	ABOUT_PAGE: lazy(() => import('../pages/AboutPage')),
	CAREER_PAGE: lazy(() => import('../pages/careerPages/CareerPage')),
	CONTACT_PAGE: lazy(() => import('../pages/ContactPage')),
};

const HIDE_PAGES = {
	ADD_SERVICE_PAGE: lazy(() => import('../pages/whatWeDoPages/AddNewServicePage')),
	EDIT_SERVICE_PAGE: lazy(() => import('../pages/whatWeDoPages/EditServicePage')),
	ADD_WORK_PAGE: lazy(() => import('../pages/ourWorkPages/AddNewWorkPage')),
	EDIT_WORK_PAGE: lazy(() => import('../pages/ourWorkPages/EditWorkInfoPage')),
	CREATE_VACANCY_PAGE: lazy(() => import('../pages/careerPages/CreateVacancyPage')),
	EDIT_VACANCY_PAGE: lazy(() => import('../pages/careerPages/EditVacancyPage')),
};

const DEFAULT = {
	PAGE_404: lazy(() => import('../pages/Page404')),
	IN_DEVELOPMENT: lazy(() => import('../pages/InDevelopmentPage')),
};

const AUTH = {
	LOGIN_PAGE: lazy(() => import('../pages/Login')),
	PROFILE_PAGE: lazy(() => import('../pages/ProfilePage')),
};

const mainPages = [
	{
		path: mainPagesMenu.homePage.path,
		element: (
			<ProtectedRoute>
				<MAIN_PAGES.HOME_PAGE />
			</ProtectedRoute>
		),
		exact: true,
	},
	{
		path: mainPagesMenu.whatWeDoPage.path,
		element: (
			<ProtectedRoute>
				<MAIN_PAGES.WHAT_WE_DO_PAGE />
			</ProtectedRoute>
		),
		exact: true,
	},
	{
		path: mainPagesMenu.ourWorkPage.path,
		element: (
			<ProtectedRoute>
				<MAIN_PAGES.OUR_WORK_PAGE />
			</ProtectedRoute>
		),
		exact: true,
	},
	{
		path: mainPagesMenu.aboutPage.path,
		element: (
			<ProtectedRoute>
				<MAIN_PAGES.ABOUT_PAGE />
			</ProtectedRoute>
		),
		exact: true,
	},
	{
		path: mainPagesMenu.careersPage.path,
		element: (
			<ProtectedRoute>
				<MAIN_PAGES.CAREER_PAGE />
			</ProtectedRoute>
		),
		exact: true,
	},
	{
		path: mainPagesMenu.contactPage.path,
		element: (
			<ProtectedRoute>
				<MAIN_PAGES.CONTACT_PAGE />
			</ProtectedRoute>
		),
		exact: true,
	},
];

const hidePages = [
	{
		path: hdiePagesMenu.addNewServicePage.path,
		element: <HIDE_PAGES.ADD_SERVICE_PAGE />,
		exact: true,
	},
	{
		path: hdiePagesMenu.editServicePage.path,
		element: <HIDE_PAGES.EDIT_SERVICE_PAGE />,
		exact: true,
	},
	{
		path: hdiePagesMenu.addNewWorkPage.path,
		element: <HIDE_PAGES.ADD_WORK_PAGE />,
		exact: true,
	},
	{
		path: hdiePagesMenu.editWorkPage.path,
		element: <HIDE_PAGES.EDIT_WORK_PAGE />,
		exact: true,
	},
	{
		path: hdiePagesMenu.createVacancyPage.path,
		element: <HIDE_PAGES.CREATE_VACANCY_PAGE />,
		exact: true,
	},
	{
		path: hdiePagesMenu.editVacancyPage.path,
		element: <HIDE_PAGES.EDIT_VACANCY_PAGE />,
		exact: true,
	},
];

const defaultPages = [
	{
		path: defaultPagesMenu.page404.path,
		element: <DEFAULT.PAGE_404 />,
		exact: true,
	},
	{
		path: defaultPagesMenu.inDevelopment.path,
		element: <DEFAULT.IN_DEVELOPMENT />,
		exact: true,
	},
];

const authPages = [
	{
		path: authPagesMenu.loginPage.path,
		element: <AUTH.LOGIN_PAGE />,
		exact: true,
	},
	{
		path: authPagesMenu.profilePage.path,
		element: <AUTH.PROFILE_PAGE />,
		exact: true,
	},
];

const contentRoutes = [...authPages, ...mainPages, ...hidePages, ...defaultPages];

export default contentRoutes;
