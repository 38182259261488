export const mainPagesMenu = {
	homePage: {
		id: 'homePage',
		text: 'Home',
		path: '/home',
		icon: 'HouseFill',
	},
	whatWeDoPage: {
		id: 'whatWeDoPage',
		text: 'What We Do',
		path: '/whatWeDo',
		icon: 'Binoculars',
	},
	ourWorkPage: {
		id: 'ourWorkPage',
		text: 'Our Work',
		path: '/ourWork',
		icon: 'ClipboardData',
	},
	careersPage: {
		id: 'careersPage',
		text: 'Careers',
		path: '/careers',
		icon: 'FileEarmarkPerson',
	},
	contactPage: {
		id: 'contactPage',
		text: 'Contact',
		path: '/contact',
		icon: 'Headset',
	},
	aboutPage: {
		id: 'aboutPage',
		text: 'About',
		path: '/about',
		icon: 'InfoCircle',
	},
};

export const hdiePagesMenu = {
	addNewServicePage: {
		id: 'addNewServicePage',
		text: 'Add New Service',
		path: '/addNewService',
		icon: 'AddBusiness',
	},
	editServicePage: {
		id: 'editServicePage',
		text: 'Edit Service',
		path: '/editService',
		icon: 'EditNote',
	},
	addNewWorkPage: {
		id: 'addNewWorkPage',
		text: 'Add New Work',
		path: '/addNewWork',
		icon: 'AddBusiness',
	},
	editWorkPage: {
		id: 'editWorkPage',
		text: 'Edit Work',
		path: '/editWork',
		icon: 'EditNote',
	},
	createVacancyPage: {
		id: 'createVacancyPage',
		text: 'Create Vacancy',
		path: '/createVacancy',
		icon: 'AddBusiness',
	},
	editVacancyPage: {
		id: 'editVacancyPage',
		text: 'Edit Vacancy',
		path: '/editVacancy',
		icon: 'EditNote',
	},
};

export const defaultPagesMenu = {
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '/404',
		icon: 'ReportGmailerrorred',
	},
	inDevelopment: {
		id: 'InDevelopment',
		text: 'In Development',
		path: '/inDevelopment',
		icon: 'ReportGmailerrorred',
	},
};

export const authPagesMenu = {
	loginPage: {
		id: 'loginPage',
		text: 'Login Page',
		path: '/',
		icon: 'ReportGmailerrorred',
	},
	profilePage: {
		id: 'profilePage',
		text: 'Profile Page',
		path: '/profilePage',
		icon: 'AccountBox',
	},
};
